//======> Tornado UI Core Base <=======//
import './Base/Tornado';
import Tornado from './Base/Tornado';
// import WordPress from './Base/wordpress';

document.addEventListener('DOMContentLoaded', DOMREADY => {
    //====> Header in Hero <====//
    Tornado.getElements('.main-header, .moving-element, .tornado-header').forEach(element => {
        Tornado.getElement('.header-included')?.prepend(element);
    });

    //====> Hero Slider <====//
    Tornado.slider('.hero-slider', {
        items      : 1,     //===> Display Slides Count
        duration   : 8000,  //===> Autoplay Duration
        pagination : true,   //===> Show Dots Pagination
        controls   : true,   //===> Show Arrows
        animation  : "gallery",    //===> Animation Mode [carousel, gallery]
        speed      : 1000,           //===> Animation Speed
    });

    //====> Carousel Slider <====//
    Tornado.getElements('.carousel-slider').forEach(element => {
        //====> Dynamic Options <====//
        var itemsNumber = element.getAttribute('data-items'),
            itemsSmall  = element.getAttribute('data-sm'),
            itemsMedium = element.getAttribute('data-md'),
            itemsLarge  = element.getAttribute('data-lg'),
            controls    = element.getAttribute('data-controls'),
            cssClass    = element.getAttribute('data-class') || 'carousel-slider-outer',
            pagination  = element.getAttribute('data-pagination');

        //====> Create Slider <====//
        Tornado.slider(element, {
            duration   : 7000,
            speed      : 700,
            items      : parseInt(itemsNumber) || 1,
            pagination : pagination ? true : false,
            controls   : controls ? true : false,
            uniuqClass : cssClass,
            responsive : {
                small  : { items: parseInt(itemsSmall)  || 2},
                medium : { items: parseInt(itemsMedium) || 3},
                large  : { items: parseInt(itemsLarge)  || 4} 
            }
        });
    });

    //====> Gallery Slider <====//
    Tornado.slider('.gallery-slider .theSlider', {
        items      : 1,     //===> Display Slides Count
        duration   : 8000,  //===> Autoplay Duration
        pagination : true,   //===> Show Dots Pagination
        controls   : true,   //===> Show Arrows
        animation  : "gallery",    //===> Animation Mode [carousel, gallery]
        speed      : 1000,           //===> Animation Speed
        navAsThumbnails : true,
        navContainer    : ".gallery-slider .thumbnails",
    });

    //====> Header in Hero <====//
    Tornado.getElement('.with-dark-header')?.querySelector('.tornado-header')?.classList.add('darker');

    //====> Menu Toggle <====//
    Tornado.getElements('.menu-toggle').forEach(button => {
        button.addEventListener('click', event => {
            event.preventDefault();
            //===> Desktop Mode <===//
            if(Tornado.viewport.width() > 750) {
                Tornado.getNextSibling({
                    element : button,
                    filter : '.navigation-menu'
                })?.classList.toggle('closed');
            }
            //===> Mobile Mode <===//
            else {
                var MenuID = button.getAttrobute('data-id');
                Tornado.getElement(`#${MenuID}`).classList.add('active');
            }
        });
    });

    //====> Map Trigger <====//
    Tornado.getElements('.map-trigger').forEach((button, index) => {
        //====> Get Lat & Long <====//
        let lat = button.getAttribute('data-lat'),
            long = button.getAttribute('data-long'),
            source = `https://www.google.com/maps/embed/v1/place?key=AIzaSyC1mhMw54vtLNR9-kgqpZ5mewt-qpY4N7E&q=${lat},${long}`;
        //====> Set the First <====//
        if (index < 1) Tornado.getElement('#map iframe')?.setAttribute('src', source);
        if (index < 1) button.classList.add('primary');
        //====> Click to Get <====//
        button.addEventListener('click', event => {
            event.preventDefault();
            let lat = button.getAttribute('data-lat'),
                long = button.getAttribute('data-long'),
                source = `https://www.google.com/maps/embed/v1/place?key=AIzaSyC1mhMw54vtLNR9-kgqpZ5mewt-qpY4N7E&q=${lat},${long}`;

            Tornado.getElement('#map iframe')?.setAttribute('src', source);
            Tornado.getElements('.map-trigger.primary').forEach(btn => btn.classList.remove('primary'));
            button.classList.add('primary');
        });
    });

    
});

